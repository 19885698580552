<template>
  <section class="banner bg-opacity">
    <div class="main-container">
      <img class="logo" alt="Devices logo" src="../assets/images/logo.png"/>
      <h1>{{ $t("banner.title") }}</h1>
      <div class="border-hr"></div>
      <p>{{ $t("banner.welcome_text") }}</p>
    </div>
    <div class="section-bg-1"></div>
  </section>
  <section class="device-1">
    <div class="main-container">
      <DeviceHome
          :description="$t('soilSensor_home.description')"
          :icon="require('@/assets/SoilSensor/cover.jpg')"
          :more="$t('more')"
          :name="$t('soilSensor_home.name')"
          :title="$t('soilSensor_home.title')"
          link="/soil-sensor"
      />
    </div>
  </section>
  <div class="section-bg-2"></div>
  <section class="device-2">
    <div class="main-container">
      <DeviceHome
          :description="$t('airLogger_home.description')"
          :icon="require('@/assets/AirLogger/cover.jpg')"
          :more="$t('more')"
          :name="$t('airLogger_home.name')"
          :style="{ 'flex-direction': 'row-reverse', 'text-align': 'left' }"
          :title="$t('airLogger_home.title')"
          link="/air-logger"
      />
    </div>
  </section>
  <div class="section-bg-3"></div>
  <section class="device-3">
    <div class="main-container">
      <DeviceHome
          :description="$t('microUps_home.description')"
          :icon="require('@/assets/MicroUPS/cover.jpg')"
          :more="$t('more')"
          :name="$t('microUps_home.name')"
          :title="$t('microUps_home.title')"
          link="/micro-ups"
      />
    </div>
  </section>
  <div class="section-bg-4"></div>
  <section class="bg-1">
    <div class="main-container">
      <h2>{{ $t("articles.name") }}</h2>
      <div class="border-hr"></div>
      <div class="article-container">
        <ArticlesHome
            :description="$t('setting_for_esp.description')"
            :icon="require('@/assets/articles/article1/arduino/6.jpg')"
            :more="$t('more')"
            :title="$t('setting_for_esp.title')"
            link="/articles/setting_for_esp#1"
        />
        <ArticlesHome
            :description="$t('dev_in_a_cont.description.home')"
            :icon="require('@/assets/articles/article2/cover.png')"
            :more="$t('more')"
            :title="$t('dev_in_a_cont.title')"
            link="/articles/development#2"
        />
      </div>
    </div>
  </section>
  <div class="section-bg-5"></div>
</template>

<script>
import DeviceHome from "@/components/Device";
import ArticlesHome from "@/components/ArticlesHome";

export default {
  name: "HomeView",
  components: {ArticlesHome, DeviceHome}
};
</script>
<style lang="scss" scoped>;
.banner {
  .logo {
    width: $img-logo;
    height: $img-logo;
    @extend %radius-circle;
  }
}

.article-container {
  @extend %flex-row;
}

@include for-size(phone) {
  .banner {
    font-size: $size-med;
    padding: {
      top: calc($size-lrg * 2.5);
    }

    h1 {
      font-size: calc($size-lrg * 2);
    }

    p {
      line-height: $lh-sml;
    }

    .section-bg-1 {
      @include bg-gradient($deg: $bg-deg-1, $bg-color-1: $base-color-transparent, $bg-color-2: $base-color, $perc-1: 58%, $perc-2: 59%, $height: calc($bg-height / 2));
    }
  }
  .section-bg-2 {
    @include bg-gradient($deg: -$bg-deg-1, $bg-color-1: $base-color, $bg-color-2: $base-color-2, $perc-1: 58%, $perc-2: 59%, $height: calc($bg-height / 2));
  }
  .section-bg-3 {
    @include bg-gradient($deg: $bg-deg-1, $bg-color-1: $base-color-2, $bg-color-2: $base-color-3, $perc-1: 58%, $perc-2: 59%, $height: calc($bg-height / 2));
  }
  .section-bg-4 {
    @include bg-gradient($deg: -$bg-deg-1, $bg-color-1: $base-color-3, $bg-color-2: $base-color-4, $perc-1: 59%, $perc-2: 60%, $height: calc($bg-height / 2));
  }
  .section-bg-5 {
    @include bg-gradient($deg: $bg-deg-1, $bg-color-1: $base-color-4, $bg-color-2: $base-color-transparent, $perc-1: 58%, $perc-2: 59%, $height: calc($bg-height / 2));
  }
}

@include for-size(tablet-portrait) {
  .banner {
    font-size: $size-med;
    padding: {
      top: calc($size-lrg * 3.5);
    }

    h1 {
      font-size: calc($size-lrg * 2.75);
    }

    .section-bg-1 {
      @include bg-gradient($deg: $bg-deg-2, $bg-color-1: $base-color-transparent, $bg-color-2: $base-color, $perc-1: 58%, $perc-2: 59%, $height: calc($bg-height / 1.5));
    }
  }
  .section-bg-2 {
    @include bg-gradient($deg: -$bg-deg-2, $bg-color-1: $base-color, $bg-color-2: $base-color-2, $perc-1: 58%, $perc-2: 59%, $height: calc($bg-height / 1.5));
  }
  .section-bg-3 {
    @include bg-gradient($deg: $bg-deg-2, $bg-color-1: $base-color-2, $bg-color-2: $base-color-3, $perc-1: 58%, $perc-2: 59%, $height: calc($bg-height / 1.5));
  }
  .section-bg-4 {
    @include bg-gradient($deg: -$bg-deg-2, $bg-color-1: $base-color-3, $bg-color-2: $base-color-4, $perc-1: 59%, $perc-2: 60%, $height: calc($bg-height / 1.5));
  }
  .section-bg-5 {
    @include bg-gradient($deg: $bg-deg-2, $bg-color-1: $base-color-4, $bg-color-2: $base-color-transparent, $perc-1: 58%, $perc-2: 59%, $height: calc($bg-height / 1.5));
  }
}

@include for-size(tablet-landscape) {
  .banner {
    p {
      letter-spacing: $l-space;
      line-height: $lh-lrg;
    }

    .section-bg-1 {
      @include bg-gradient($deg: $bg-deg-2, $bg-color-1: $base-color-transparent, $bg-color-2: $base-color, $perc-1: 58%, $perc-2: 59%, $height: $bg-height);
    }
  }
  .section-bg-2 {
    @include bg-gradient($deg: -$bg-deg-2, $bg-color-1: $base-color, $bg-color-2: $base-color-2, $perc-1: 58%, $perc-2: 59%, $height: $bg-height);
  }
  .section-bg-3 {
    @include bg-gradient($deg: $bg-deg-2, $bg-color-1: $base-color-2, $bg-color-2: $base-color-3, $perc-1: 58%, $perc-2: 59%, $height: $bg-height);
  }
  .section-bg-4 {
    @include bg-gradient($deg: -$bg-deg-2, $bg-color-1: $base-color-3, $bg-color-2: $base-color-4, $perc-1: 59%, $perc-2: 60%, $height: $bg-height);
  }
  .section-bg-5 {
    @include bg-gradient($deg: $bg-deg-2, $bg-color-1: $base-color-4, $bg-color-2: $base-color-transparent, $perc-1: 58%, $perc-2: 59%, $height: $bg-height);
  }
}

@include for-size(desktop) {
  .banner {
    font-size: $size-lrg;
    padding: {
      top: calc($size-lrg * 6.25);
    }

    h1 {
      font-size: calc($size-lrg * 3.5);
    }

    .section-bg-1 {
      @include bg-gradient($deg: $bg-deg-2, $bg-color-1: $base-color-transparent, $bg-color-2: $base-color, $perc-1: 58%, $perc-2: 59%, $height: calc($bg-height * 1.6));
    }
  }
  .article-container {
    @extend %flex-row;
  }

  .section-bg-2 {
    @include bg-gradient($deg: -$bg-deg-2, $bg-color-1: $base-color, $bg-color-2: $base-color-2, $perc-1: 58%, $perc-2: 59%, $height: calc($bg-height * 1.6));
  }

  .section-bg-3 {
    @include bg-gradient($deg: $bg-deg-2, $bg-color-1: $base-color-2, $bg-color-2: $base-color-3, $perc-1: 58%, $perc-2: 59%, $height: calc($bg-height * 1.6));
  }

  .section-bg-4 {
    @include bg-gradient($deg: -$bg-deg-2, $bg-color-1: $base-color-3, $bg-color-2: $base-color-4, $perc-1: 59%, $perc-2: 60%, $height: calc($bg-height * 1.6));
  }

  .section-bg-5 {
    @include bg-gradient($deg: $bg-deg-2, $bg-color-1: $base-color-4, $bg-color-2: $base-color-transparent, $perc-1: 58%, $perc-2: 59%, $height: calc($bg-height * 1.6));
  }
}
</style>
