import {createRouter, createWebHashHistory} from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {title: 'Devices'}
    },

    {
        path: "/soil-sensor",
        name: "SoilSensor",
        component: function () {
            return import("../views/SoilSensorView.vue");
        },
        meta: {title: 'SoilSensor'},
    },
    {
        path: "/air-logger",
        name: "AirLogger",
        component: function () {
            return import("../views/AirLogger.vue");
        },
        meta: {title: 'AirLogger'},
    },
    {
        path: "/micro-ups",
        name: "MicroUPS",
        component: function () {
            return import("../views/MicroUPS.vue");
        },
        meta: {title: 'MicroUPS'},
    },
    {
        path: "/articles",
        name: "ArticlesView",
        component: function () {
            return import("../views/ArticlesView.vue");
        },
        meta: {title: 'Articles'},
        children: [
            {
                path: 'setting_for_esp',
                name: "SettingForEsp",
                component: function () {
                    return import("../components/SettingForEsp.vue");
                },
                meta: {
                    title: "Setting up CLion + esp-idf + Arduino core for the ESP32 on Windows"
                },
            },
            {
                path: 'development',
                name: "DevInContainer",
                component: function () {
                    return import("../components/DevInContainer.vue");
                },
                meta: {
                    title: "Development in a Container"
                },
            }
        ]
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {el: to.hash, top: 100};
        }
        return {top: 0};
    }
});
router.beforeEach((to, from, next) => {
    const title = to.meta.title
    if (title) {
        document.title = title
    }
    next()
})
export default router;
