<template>
  <div class="container">
    <img class="image" :src="icon" alt="article icon"/>
    <div class="content">
      <h3>
        {{ title }}
      </h3>
      <div class="border-hr"></div>
      <p>
        {{ description }}
      </p>
      <router-link :to="link">
        <span @mouseover="hovered = true" @mouseleave="hovered = false">
          <img v-if="!hovered" class="arrow" src="@/assets/images/arrow.png" alt="arrow"/>
          <img v-else-if="hovered" class="arrow" src="@/assets/images/arrow2.png" alt="arrow">
        {{ more }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArticlesHome",
  props: ["title", "description", "more", "icon", "link", "arrow"],
  data() {
    return {
      hovered: false
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  @extend %border-radius;
  @extend %flex-column;
  background-color: $base-color-5;
  margin: calc($size-lrg / 2);
  flex: 2;

  .image {
    border: {
      top-left-radius: $border-rds;
      top-right-radius: $border-rds;
    }
    height: $height-img;
  }

  .content {
    margin: calc($size-lrg / 2);
  }

  @include for-size(phone) {
    margin: 0 {
      top: calc($size-lrg / 2);
    };
    .content {
      h3 {
        height: fit-content;
      }

      p {
        height: $height-p;
      }
    }
  }
  @include for-size(tablet-portrait) {
    .image {
      height: calc($height-img * 1.5);
    }
  }
  @include for-size(tablet-landscape) {
    .content {
      h3 {
        height: calc($height-h / 1.5);
      }

      p {
        height: calc($height-p * 1.75);
      }
    }
  }
  @include for-size(desktop) {
    .image {
      height: calc($height-img * 2);
    }

    .content {
      h3 {
        height: calc($height-h / 1.2);
      }
    }
  }
}
</style>
