<template>
  <nav>
    <div class="btn-nav" @click="showMenu=true">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>
    <div v-show="showMenu" @click="showMenu=false" class="menu-wrap">
      <div class="menu-content">
        <div>
          <router-link to="/">{{ $t("menu.home") }}</router-link>
        </div>
        <div>
          <router-link to="/soil-sensor">SoilSensor</router-link>
        </div>
        <div>
          <router-link to="/air-logger">AirLogger</router-link>
        </div>
        <div>
          <router-link to="/micro-ups">MicroUPS</router-link>
        </div>
        <div>
          <router-link to="/articles">{{ $t("menu.articles") }}</router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "MenuNav",
  data() {
    return {
      showMenu: false
    }
  }
};
</script>

<style lang="scss" scoped>
nav {
  @extend %flex-row;

  .btn-nav {
    @extend %border-base;
    @extend %border-radius;
    @extend %flex-column;
    justify-content: center;
    height: $icon-menu;
    width: $icon-menu;

    .line {
      border: $br-width solid $base-white;
      border-radius: 0.1rem;
      margin: {
        bottom: calc($indent / 10);
        left: calc($indent / 2);
        right: calc($indent / 2);
        top: calc($indent / 10);
      }
    }

    &:hover {
      @extend %radius-circle;

      .line {
        margin: {
          bottom: calc($indent / 5);
          left: calc($indent / 1.6);
          right: calc($indent / 1.6);
          top: calc($indent / 5);
        }
      }
    }

    @include for-size(desktop) {
      height: calc($icon-menu * 1.4);
      width: calc($icon-menu * 1.4);
      .line {
        margin: {
          bottom: calc($indent / 3);
          top: calc($indent / 3);
        }
      }
    }
  }

  .menu-wrap {
    @extend %flex-column;
    justify-content: center;
    align-items: center;
    background-color: $dark-opacity-2;
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;

    .menu-content {
      @extend %border-radius;
      @extend %flex-column;
      align-items: center;
      background: $base-color-4;
      width: calc($img-dev * 1.25);
      @include for-size(desktop) {
        width: calc($img-dev * 1.6);
      }

      div {
        margin: $indent;

        a {
          @extend %text-uppercase;
          font-size: $size-med;
          letter-spacing: $l-space;
        }

        &:hover {
          transform: scale($scale);
        }
      }
    }
  }
}


</style>
