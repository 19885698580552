export default {
  "menu": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["головна"])},
    "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["статті"])}
  },
  "banner": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вітаємо!"])},
    "welcome_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На сайті представлено приклади проєктів та фото приладів, що реалізовано на мікроконтролерах Arduino та ESP32. Також тут можна знайти корисну інформацію, яка може знадобитися при розробці таких проєктів."])}
  },
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Більше"])},
  "btn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["розгорнути"])},
  "btn-less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["згорнути"])},
  "soilSensor_home": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SoilSensor"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пристрій поливу кімнатних рослин"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цей пристрій призначений для автоматичного поливу рослин у горщиках. Коли ви тривалий час не вдома і немає можливості полити рослини - можна скористатися SoilSensor. Прилад вимірює рівень вологи ґрунту і зрошує лише за необхідності."])}
  },
  "airLogger_home": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AirLogger"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монітор якості повітря"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Призначений для моніторингу основних параметрів якості повітря в побутових приміщеннях. Відображає на екрані показники датчиків CO2, температури, вологи і тиску повітря. Записує параметри на карту MicroSD."])}
  },
  "microUps_home": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MicroUPS"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безперебійне живлення зі зворотним зв'язком"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Джерело безперебійного живлення на 5 вольт із зворотнім зв'язком. Ємність 20000мАч, вихідна напруга 5В 3А. Особливістю пристрою є те, що після відключення живлення і повної розрядки, а потім включення, живлення споживача буде відновлено автоматично без необхідності включати його вручну."])}
  },
  "articles": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статті"])}
  },
  "soilSensor_page": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цей пристрій призначений для автоматичного поливу рослин у горщиках. Коли ви тривалий час не вдома і немає можливості полити рослини - можна скористатися SoilSensor. Прилад вимірює рівень вологи ґрунту і зрошує лише за необхідності. Є можливість налаштувати бажаний рівень вологості ґрунту та \"тихі години\" - враховуючи, коли пристрій не включатиме полив, що, наприклад, дозволить не створювати зайвий шум у нічний час доби. Також автоматичний полив можна вимкнути, в такому випадку пристрій просто нагадуватиме про необхідність поливу за допомогою світлової індикації. Для відображення стану та налаштування параметрів роботи пристрій має рідкокристалічний екран з підсвічуванням та чотири кнопки. Пристрій відображає поточну температуру та вологість повітря на екрані, а також має слот для картки пам'яті, на яку може записувати значення показань датчиків та часу включення поливу."])},
    "list_functions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функції"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматичний полив"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Захист від спорожнення/переповнення контейнера"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Індикація рівня вологості ґрунту, температури та вологості повітря"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миготлива світлова індикація, коли потрібен полив"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відображення дати та часу"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запис параметрів на карту MicroSD"])}
    },
    "list_apply": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Використані компоненти"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arduino Nano V3 ATmega328P-AU"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ємнісний датчик вологості грунту"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Датчик вологості та температури GY-21 HTU21"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCD дисплей 16x2 (LCD 1602)"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модуль картки MicroSD"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модуль реле 5В 10А"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гідронасос занурювальний 3-6V 120 л/год"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DC-DC понижуючий перетворювач напруги LM2596"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блок живлення 12V"])}
    },
    "list_package": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комплектація"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пристрій зрошення"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Датчик вологості грунту"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Занурювальний гідравлічний насос або клапан"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блок живлення"])}
    }
  },
  "airLogger_page": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пристрій призначений для моніторингу основних параметрів якості повітря в приміщенні. Дисплей показує значення: датчиків CO2, температури, вологості та тиску повітря. Записує параметри на карту MicroSD. Також пристрій може будувати графіки на екрані для кожного з відображених параметрів протягом однієї доби. Прилад оснащений акумуляторною батареєю, яка забезпечує до десяти годин роботи. Може використовуватися для реєстрації та подальшого аналізу якості повітря в офісах і квартирах. За допомогою пристрою можна визначити, чи потрібна додаткова вентиляція для обігріву або охолодження повітря в приміщенні, виходячи з історії за тривалий період, а не тільки за поточними показаннями."])},
    "list_functions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функції"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показує параметри якості повітря:"])},
      "sublist": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рівень CO2 (вуглекислого газу)"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Температуру повітря"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вологість повітря"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атмосферний тиск"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поточні дату і час"])}
      },
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запис параметрів на картку microSD"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автономна робота від вбудованих акумуляторів до 10 годин"])}
    },
    "list_apply": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Використані компоненти"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OLED дисплей 0.96' I2C 128x64"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wi-Fi модуль LuaNode32 з ESP-32"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2x Liitokala NCR18650B 3400mAh"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Датчик температури, вологості, тиску (BME280)"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модуль картки MicroSD"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модуль годинника DS1307"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Датчик CO2 (MG-811) або SENSEAIR S8"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2x DC-DC понижуючий перетворювач напруги 3А (MP1584)"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блок живлення 12V"])}
    },
    "list_package": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комплектація"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пристрій"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блок живлення"])}
    }
  },
  "microUps_page": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Джерело безперебійного живлення на 5 вольт із зворотнім зв'язком. Ємність 20000мАч, вихідна напруга 5В 3А. Особливістю пристрою є те, що після відключення живлення і повної розрядки, а потім включення, живлення споживача буде відновлено автоматично без необхідності включати його вручну. Також наявність зворотного зв'язку дозволяє інформувати споживача про рівень заряду акумулятора і наявність зовнішнього джерела живлення. Пристрій призначений для живлення міні-комп’ютерів, таких як Raspberry PI, автомобільний відеореєстратор та інших споживачів, які можна живити від стандартного джерела живлення USB."])},
    "list_functions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функції"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забезпечення джерела безперебійного живлення напругою 5В і максимальною силою струму 3А"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інтерфейс зворотного зв'язку"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Індикатор рівня заряду батареї та відображення історії відключень електромережі на OLED-дисплеї"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вимикає звукове сповіщення при відключенні зовнішнього живлення"])}
    },
    "list_apply": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Використані компоненти"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в розробці..."])}
    },
    "list_package": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комплектація"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пристрій"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автомобільний зарядний пристрій (опціонально)"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блок живлення"])}
    }
  },
  "setting_for_esp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування CLion + esp-idf + Arduino core для ESP32 у Windows"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У цій статті надано покрокові інструкції, як налаштувати CLion для розробки з ESP32 і запуску власних ескізів Arduino IDE. Ця інструкція передбачає, що ви використовуєте операційну систему Windows 10."])},
    "software_setting": {
      "description": {
        "p_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arduino IDE корисна і проста в установці для роботи з невеликими ескізами, але для професійного використання або для трохи більших проектів цього просто недостатньо. Відсутність функцій git, форматування та дослідження коду, багатомодульних функцій робить його більше схожим на розширений блокнот, але не на IDE."])},
        "p_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На відміну від Arduino IDE, Intellij CLion має всі зазначені функції, але вимагає більш складного налаштування."])},
        "p_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У цій статті я дам покрокові інструкції, як налаштувати CLion для розробки з ESP32 і запуску власних ескізів Arduino IDE."])},
        "p_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ця інструкція передбачає, що ви використовуєте Windows 10 як операційну систему."])}
      },
      "required": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Необхідне програмне забезпечення:"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intellij CLion - середовище розробки"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MinGW - середовище розробки під Windows"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esp-idf - офіційний інструментарій для розробки під ESP32"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arduino core для ESP32 - набор Arduino-сумісних бібліотек для ESP32"])}
      }
    },
    "min-gw": {
      "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Переходимо на ", _interpolate(_named("url_min"))])},
      "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Натискаємо на посилання ", _interpolate(_named("sourceforge")), "."])},
      "3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Завантажуємо ", _interpolate(_named("bold_min")), "."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберігаємо та запускаємо завантажений файл."])},
      "5": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Встановлюємо в папку ", _interpolate(_named("italic_folder")), "."])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Використовуйте налаштування за замовчуванням під час встановлення."])}
    },
    "esp-idf": {
      "p": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Для завантаження ", _interpolate(_named("bold_esp")), ", нам знадобиться ", _interpolate(_named("bold_gitBash")), ", який можна завантажити за посиланням ", _interpolate(_named("url_esp")), ". Сторінку версії esp-idf можна знайти за адресою ", _interpolate(_named("url_esp2")), ". У цьому прикладі ми будемо завантажувати ", _interpolate(_named("bold_esp")), " до папки ", _interpolate(_named("italic_folder")), "."])},
      "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Відкриваємо ", _interpolate(_named("bold_gitBash")), "."])},
      "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Переходимо до папки ", _interpolate(_named("italic_folder")), ": ", _interpolate(_named("esp_code"))])},
      "3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Клонуємо репозиторій: ", _interpolate(_named("esp_code2"))])}
    },
    "esp-idf-tools": {
      "p": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Найпростіший спосіб інсталювати все програмне забезпечення, потрібне для ", _interpolate(_named("bold_esp")), " — це скористатися інсталятором."])},
      "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Завантажити та запустити ", _interpolate(_named("url_usp_tools")), ", також посилання для завантаження доступне за адресою ", _interpolate(_named("url_usp_tools2")), ". Під час інсталяції з’являтимуться різні вікна, просто зачекайте та нічого не закривайте. Надайте доступ адміністратора за запитом."])},
      "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Далі нам потрібно встановити залежності для ", _interpolate(_named("python")), ", для цього запускаємо ", _interpolate(_named("arrow")), " і виконуємо: ", _interpolate(_named("esp_tools_code"))])},
      "p2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Не забудьте замінити ", _interpolate(_named("admin")), " своїм локальним іменем користувача."])}
    },
    "env": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування змінних середовища"])},
      "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вікриваємо ", _interpolate(_named("arrow"))])},
      "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Переходимо до папки ", _interpolate(_named("esp_path")), " і запускаємо ", _interpolate(_named("install")), " ", _interpolate(_named("env_code"))])},
      "3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Тепер, коли змінні середовища встановлено, нам потрібно скопіювати їх із середовища консолі в середовище операційної системи, що зробить їх доступними для CLion. Введіть ", _interpolate(_named("set")), ", щоб вивести всі змінні середовища на консоль. Нас цікавлять лише змінні, які починаються з ", _interpolate(_named("idf")), "."])},
      "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Наприклад, щоб додати змінну ", _interpolate(_named("env_code_2")), ", нам потрібно:"])},
      "sublist": {
        "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Натискаємо кнопку ", _interpolate(_named("start")), " і в пошуку вводимо ", _interpolate(_named("env")), " запускаємо ", _interpolate(_named("edit_system")), "."])},
        "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Натискаємо ", _interpolate(_named("env_var")), "."])},
        "3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["У нижній частині вікна натискаємо ", _interpolate(_named("new")), "."])},
        "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Заповнюємо ім'я (", _interpolate(_named("idf_path")), ") та значення (", _interpolate(_named("esp_path")), "), потім натискаємо ", _interpolate(_named("ok")), "."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заповнюємо решту змінних."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Також нам потрібно додати додаткові шляхи до змінної PATH."])},
        "7": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Закрийте всі вікна, клацнувши ", _interpolate(_named("ok")), "."])}
      },
      "5": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Список змінних: ", _interpolate(_named("env_code_3")), " Останню змінну додавати не обов'язково, тому що вона вже є у змінних користувача. Також замість системних змінних всі змінні можна додавати до списку змінних користувача."])},
      "6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Шляхи, які потрібно додати до змінної PATH: ", _interpolate(_named("env_code_4"))])}
    },
    "hello-world": {
      "p": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Щоб переконатися, що ", _interpolate(_named("bold_esp")), " налаштовано правильно, створимо просту програму з прикладів. У цьому посібнику ми будемо використовувати папку ", _interpolate(_named("folder_c")), " для зберігання джерел."])},
      "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Скопіюємо приклад ", _interpolate(_named("path_hello")), " в папку ", _interpolate(_named("c_src")), ", щоб вийшло ", _interpolate(_named("c_src_hello")), "."])},
      "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Далі запускаємо ", _interpolate(_named("clion")), " і вибираємо в меню ", _interpolate(_named("new_cMake")), "."])},
      "3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вибираємо ", _interpolate(_named("select")), "."])},
      "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вибираємо ", _interpolate(_named("open_src")), ". Після завантаження проекту нам потрібно налаштувати Toolchains."])},
      "5": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("toolchains")), "."])},
      "6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Натискаємо ", _interpolate(_named("plus")), " і вибираємо ", _interpolate(_named("minGW")), "."])},
      "7": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["В полі ", _interpolate(_named("environment")), " вказуємо шлях до встановленного ", _interpolate(_named("minGW")), "."])},
      "8": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Натискаємо ", _interpolate(_named("ok")), "."])},
      "9": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Переходимо у вкладку ", _interpolate(_named("cMake")), " і очікуємо закінчення завантаження файлу ", _interpolate(_named("cMake")), "."])},
      "p2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Якщо процес пройшов успішно, ви повинні побачити надпис ", _interpolate(_named("finished")), " і в списку, що випадає, з'являться конфігурації запуску. Щоб запустити конфігурацію, натисніть кнопку ", _interpolate(_named("build")), ", а не ", _interpolate(_named("run")), ". ", _interpolate(_named("ul_configs"))])},
      "ul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Є дві основні конфігурації запуску:"])},
      "li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["зібрати прошивку"])},
      "li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["зібрати прошивку та прошити пристрій"])}
    },
    "arduino": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування ядра Arduino для ESP32"])},
      "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Відкрийте ", _interpolate(_named("bold_gitBash")), " і клонуйте репозиторій у папку ", _interpolate(_named("components")), " ", _interpolate(_named("arduino_code")), " Стабільна версія доступна лише для ", _interpolate(_named("esp3")), ", тому нам потрібно перейти на гілку ", _interpolate(_named("v4_0")), ", яка знаходиться в розробці. ", _interpolate(_named("arduino_code_2"))])},
      "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Перейдіть на ", _interpolate(_named("clion")), " і запустіть ", _interpolate(_named("idf_py")), " на вкладці «Термінал», якщо ви отримали помилку, розтягніть вікно терміналу та розгорніть вікно ", _interpolate(_named("clion")), "."])},
      "3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поставте прапорець ", _interpolate(_named("comp_config")), "."])},
      "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Поставте прапорець ", _interpolate(_named("arduino_config")), "."])},
      "5": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Натисніть ", _interpolate(_named("save")), ", а потім ", _interpolate(_named("exit")), "."])},
      "6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["На цьому кроці ми виконаємо обхідний шлях, який дозволить нам використовувати 4-ту версію фреймворку esp-ids. Коли 4-та версія фреймворку ", _interpolate(_named("arduino_core")), " стане стабільною, можливо, нам знадобиться цей обхідний шлях."])},
      "sublist": {
        "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Відкрийте ", _interpolate(_named("arduino_open")), "."])},
        "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Додайте рядок ", _interpolate(_named("arduino_add")), " одразу після ", _interpolate(_named("arduino_add_2")), "."])}
      },
      "7": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Перейменуйте ", _interpolate(_named("arduino_rename")), "."])},
      "8": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Замініть вміст main.cpp на: ", _interpolate(_named("arduino_code_3"))])},
      "9": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Перейдіть на вкладку ", _interpolate(_named("cMake")), " і натисніть значок ", _interpolate(_named("reload_cMake")), "."])},
      "10": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Натисніть значок ", _interpolate(_named("build")), "."])},
      "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тож тепер ми отримали стандартний ескіз Arduino IDE та всі функції, які постачаються з CLion IDE."])}
    },
    "software-versions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Версії програмного забезпечення, використані для написання цього посібника:"])}
    },
    "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посилання:"])}
  },
  "dev_in_a_cont": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розробка в контейнері"])},
    "description": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У цій статті надано інструкції що до створення образу Докер з налашрованим середовищем розробки для ESP32. Також наведено приклад підключення до контейнеру за допомогою CLion IDE."])},
      "p_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування ESP-IDF та CLion дла розробки проектів під ESP32 може коштувати певних зусиль та зайняти деякий час. І, якщо вам потрібно буде змінити комп'ютер чи операційну систему, то всі кроки з налаштування вам прийдеться пройти знову."])},
      "p_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щоб вирішити цю проблему можливо використати функцію Remote Development з CLion IDE та створити Docker образ, що містить все необхідне програмне забезпечення та налаштування. Навіть, якщо ви не використовуєте CLion, образ все одно може бути корисним для збирання та відлагодження проектів за допомогою командного рядка."])},
      "p_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для віддаленої розробки використаємо open-ssh server, для побудови інтерфейсу користувача NodeJS та esp-idf toolchain для збирання та завантаження образів до ESP32."])},
      "p_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Налаштування ssh серверу в Докер контейнері описано ", _interpolate(_named("this_article")), " статті. Офіційний Espressif ", _interpolate(_named("dockerfile_link")), " допоможе нам отримати середовище для збирання проекту всередині Докер контейнера."])},
      "p_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таким чином, для запуску Докер образу вам знадобиться зібрати та запустити образ Докер та під'єднатися до нього за допомогою CLion."])},
      "this": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["цій"])}
    },
    "build-docker": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Збирання та запуск Докер образу"])},
      "list-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Передумови:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".ssh каталог знаходиться в у вашому домашньому каталозі та містить згенеровані ключі"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ваш пристрій під'єднано до usb порту Докер хоста Якщо за будь-яких причин ці передумови не виконано просто внесіть відповідні зміни до docker-compose файлу перед тим, як запустити образ."])},
      "list": {
        "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Склонуйте репозиторій ", _interpolate(_named("clone_docker"))])},
        "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Зберіть образ Докер ", _interpolate(_named("build_docker"))])},
        "3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Запустіть контейнер за допомогою docker-compose ", _interpolate(_named("start_docker"))])}
      },
      "p_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Тепер ви повинні мати змогу під'єднатись до контейнера за допомогою ssh ", _interpolate(_named("docker_code_1")), " де, 192.168.3.254 це ip адреса вашого Докер хоста. Введіть пароль ", _interpolate(_named("docker_password")), "."])},
      "p_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Оновіть права доступу до змонтованих томів (цей крок потрібно виконати лише один раз) ", _interpolate(_named("docker_code_2"))])},
      "p_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Налаштуйте ім'я користувача та електронну пошту для git (цей крок можна пропустити, якщо ви не збираєтесь завантажувати код до git) ", _interpolate(_named("docker_code_3"))])},
      "p_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Тепер можна або клонувати ваш проект до ~/src каталогу, або просто скопіювати один з прикладів esp-idf ", _interpolate(_named("docker_code_4"))])}
    },
    "connect-to-docker": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підключення до образу Докер за допомогою CLion"])},
      "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Запустіть ", _interpolate(_named("clion")), " та оберіть ", _interpolate(_named("highlighted_1"))])},
      "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Натисніть ", _interpolate(_named("highlighted_2")), " та заповніть параметри підключення"])},
      "3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Натисніть кнопку ", _interpolate(_named("highlighted_3"))])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потім оберіть версію IDE та каталог проекту. Версія IDE має бути така сама, як і та, що запущена локально."])},
      "5": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Натисніть ", _interpolate(_named("highlighted_4"))])},
      "6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["У вікні ", _interpolate(_named("highlighted_8")), " проігноруйте попередження та натисніть ", _interpolate(_named("highlighted_5")), " та ", _interpolate(_named("highlighted_6"))])},
      "7": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Якщо все пройшло успішно, то ви маєте побачити рядок з текстом ", _interpolate(_named("highlighted_7")), " у вкладці ", _interpolate(_named("cMake")), "."])},
      "8": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Щоб обрати ", _interpolate(_named("cMake")), " target скористуйтеся випадаючим списком ", _interpolate(_named("highlighted_9")), " та іконкою ", _interpolate(_named("build")), ", для виконання таргету."])},
      "p": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Якщо ви не бажаєте використовувати git, щоб клонувати репозиторій, можете просто скопіювати файли звідси: ", _interpolate(_named("dockerfile"))])}
    }
  }
}