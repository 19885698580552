export default {
  "menu": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
    "articles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["articles"])}
  },
  "banner": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome!"])},
    "welcome_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This site is showing project examples and photos of devices that could be built based on the Arduino and ESP32 microcontrollers. Also here you can find some information that could be useful for the developers of such projects."])}
  },
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More details"])},
  "btn-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expand"])},
  "btn-less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["collapse"])},
  "soilSensor_home": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SoilSensor"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device of irrigation pot plants"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This device is designed for automatic watering pot plants. When you are not at home for a long time and do not have opportunity to water pot plants you can use SoilSensor. The device measures the moisture level in the soil and irrigates only when needed."])}
  },
  "airLogger_home": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AirLogger"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitor of air quality"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This device is designed for monitoring of the main air quality parameters in utility space. Display indicates: CO2, temperature, humidity and air pressure sensors values. Writes parameters to MicroSD card."])}
  },
  "microUps_home": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MicroUPS"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uninterrupted power supply with feedback"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uninterrupted power supply 5V with feedback. Capacity 20000mAh, output voltage 5V 3A. A feature of the device is that after turning off the power and completely discharging, and then turning tha power back on, the consumer's power will be resumed automatically without the need of turning it on manually."])}
  },
  "articles": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles"])}
  },
  "soilSensor_page": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This device is designed for automatic watering pot plants. When you are not at home for a long time and do not have opportunity to water pot plants you can use SoilSensor. The device measures the moisture level in the soil and irrigates only when needed. It is possible to adjust the desired soil moisture level. It is also possible to set up \"quiet hours\" - when the device will not turn on watering, which, for example, will allow you to avoid unnecessary noise at night. Also, automatic watering can be turned off, in this case the device will simply remind you when it's time to water your plants with blinking light indication. The device has liquid crystal display with backlight and four buttons for displaying the status and setting operating parameters. The device displays the current temperature and humidity on the screen and has a slot for a memory card, on which it can record the values of the sensors readings and the time when irrigation is started."])},
    "list_functions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Functions"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic irrigation"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Container emptying/overflow protection"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indication of soil moisture level, temperature and air humidity"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blinking light indication when irrigation is needed"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and time display"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Writing parameters to MicroSD card"])}
    },
    "list_apply": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied components"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arduino Nano V3 ATmega328P-AU"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacitive sensor of soil moisture"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Humidity and temperature sensor GY-21 HTU21"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCD display 16x2 (LCD 1602)"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MicroSD card module"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relay 5V 10A Module"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submersible hydraulic pump 3-6V 120 l/h"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DC-DC reducing power converter LM2596"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power supply unit 12V"])}
    },
    "list_package": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package includes"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device of irrigation"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soil moisture sensor"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submersible hydraulic pump or valve"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power supply unit"])}
    }
  },
  "airLogger_page": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This device is designed for monitoring of the main air quality parameters in utility space. Display indicates: CO2, temperature, humidity and air pressure sensors values. Writes parameters to MicroSD card. Also, the device can build graphs on the screen for each of the displayed parameters during one day. The device is equipped with a rechargeable battery that provides up to ten hours of operating time. It can be used for recording and subsequent analysis of air quality in offices and apartments. Using the device, you can determine whether additional ventilation is required for heating or cooling the air in the room, based on history over a long period, but not only on current indications."])},
    "list_functions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Functions"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Displays air quality parameters:"])},
      "sublist": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level of CO2 (carbon dioxide)"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Air temperature"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Air humidity"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atmosphere pressure"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current date and time"])}
      },
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Writing parameters to microSD card"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autonomous work from built-in batteries up to 10 hours"])}
    },
    "list_apply": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied components"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OLED display 0.96' I2C 128x64"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wi-Fi module LuaNode32 с ESP-32"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2x Liitokala NCR18650B 3400mAh"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature, humidity, pressure sensor (BME280)"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MicroSD card module"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS1307 RTC module"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO2 sensor (MG-811) or SENSEAIR S8"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2x DC-DC reducing power converter 3А (MP1584)"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power supply unit 12V"])}
    },
    "list_package": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package includes"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power supply unit"])}
    }
  },
  "microUps_page": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uninterrupted power supply 5V with feedback. Capacity 20000mAh, output voltage 5V 3A. A feature of the device is that after turning off the power and completely discharging, and then turning tha power back on, the consumer's power will be resumed automatically without the need of turning it on manually. Also, the presence of feedback allows you to inform the consumer about the battery charge level and the availability of the external power source. The device is designed to power mini-computers such as Raspberry PI, car dash cam and other consumers, which can be powered from a standard USB power source."])},
    "list_functions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Functions"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provision of uninterruptible power supply with voltage 5V and maximum current 3A"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback interface"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Battery level indicator and display of mains power outage history on OLED display"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disables all sound notifications when external power is disconnected"])}
    },
    "list_apply": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied components"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in developing..."])}
    },
    "list_package": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package includes"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car charger (optional)"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power supply unit"])}
    }
  },
  "setting_for_esp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting up CLion + esp-idf + Arduino core for the ESP32 on Windows"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this tutorial will to give step-by-step instructions how to setup CLion for developing with ESP32 and running native Arduino IDE sketches. This instruction is assumes that you are using Windows 10 as operating system."])},
    "software_setting": {
      "description": {
        "p_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arduino IDE is useful and easy to install for playing with small sketches, but for professional use or for a bit bigger projects it is just not enough. Lack of git functions, code formatting and exploring, multi modules functions makes it more like extended notepad, but not IDE."])},
        "p_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlike Arduino IDE, Intellij CLion has all mentioned features, but requires more complicated setup."])},
        "p_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this tutorial I will ty to give step-by-step instructions how to setup CLion for developing with ESP32 and running native Arduino IDE sketches."])},
        "p_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This instruction is assumes that you are using Windows 10 as operating system."])}
      },
      "required": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required software:"])},
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intellij CLion - Developer's IDE"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MinGW - Development environment for Windows"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esp-idf - Official ESP32 development toolchain"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arduino core for the ESP32 - Set of Arduino compatible libraries for ESP32"])}
      }
    },
    "min-gw": {
      "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Go to ", _interpolate(_named("url_min"))])},
      "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click ", _interpolate(_named("sourceforge")), " link."])},
      "3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Download ", _interpolate(_named("bold_min")), "."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and run downloaded file."])},
      "5": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Install in ", _interpolate(_named("italic_folder")), " folder."])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use default settings during the installation."])}
    },
    "esp-idf": {
      "p": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For downloading ", _interpolate(_named("bold_esp")), ", ", _interpolate(_named("bold_gitBash")), " is required. You can download it from ", _interpolate(_named("url_esp")), ". Esp-idf version page is located at ", _interpolate(_named("url_esp2")), ".In this example we will download ", _interpolate(_named("bold_esp")), " into ", _interpolate(_named("italic_folder")), " folder."])},
      "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Start ", _interpolate(_named("bold_gitBash")), "."])},
      "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Go to ", _interpolate(_named("italic_folder")), " folder: ", _interpolate(_named("esp_code"))])},
      "3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Clone the repository: ", _interpolate(_named("esp_code2"))])}
    },
    "esp-idf-tools": {
      "p": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The easiest way to install all software that is required for ", _interpolate(_named("bold_esp")), " is to use the installer."])},
      "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Download and run ", _interpolate(_named("url_usp_tools")), ", also download link is available at ", _interpolate(_named("url_usp_tools2")), ". During the installation process different windows will popup, just wait and do not close anything. Give Administrator's access when requested."])},
      "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Next we need to install ", _interpolate(_named("python")), " dependencies, to do this run ", _interpolate(_named("arrow")), " and execute: ", _interpolate(_named("esp_tools_code"))])},
      "p2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Don't forget to replace ", _interpolate(_named("admin")), " with your local user name."])}
    },
    "env": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup environment variables"])},
      "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Start ", _interpolate(_named("arrow"))])},
      "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Go to ", _interpolate(_named("esp_path")), " folder and run ", _interpolate(_named("install")), " ", _interpolate(_named("env_code"))])},
      "3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Now when environment variables are set, we need to copy them from the console environment into the operating system environment that will make them available for CLion. Enter ", _interpolate(_named("set")), " to dump all environment variables into the console. Only variables that starts with ", _interpolate(_named("idf")), " are interested for us."])},
      "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For example, to add ", _interpolate(_named("env_code_2")), " variable we need to:"])},
      "sublist": {
        "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click ", _interpolate(_named("start")), " button and search for ", _interpolate(_named("env")), " run ", _interpolate(_named("edit_system")), "."])},
        "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click ", _interpolate(_named("env_var")), "."])},
        "3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["At the bottom of the window click ", _interpolate(_named("new")), "."])},
        "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fill in variable Name (", _interpolate(_named("idf_path")), ") and Value (", _interpolate(_named("esp_path")), ") then click ", _interpolate(_named("ok")), "."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add all other variables."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Also we need to add additional paths to the PATH variable."])},
        "7": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Close all windows by clicking ", _interpolate(_named("ok")), "."])}
      },
      "5": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Variables list: ", _interpolate(_named("env_code_3")), " It is not required to add last variable as it is already in the variables list. Also instead of system you can use user's variables."])},
      "6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Paths that are required to add to the PATH variable: ", _interpolate(_named("env_code_4"))])}
    },
    "hello-world": {
      "p": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To verify that ", _interpolate(_named("bold_esp")), " is set up correctly let's build simple program from the esp-idf examples. In this tutorial we will use ", _interpolate(_named("folder_c")), " folder to store sources."])},
      "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Copy example ", _interpolate(_named("path_hello")), " into ", _interpolate(_named("c_src")), " folder, so we will have the ", _interpolate(_named("c_src_hello")), "."])},
      "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Next, launch ", _interpolate(_named("clion")), " and choose ", _interpolate(_named("new_cMake")), "in the menu."])},
      "3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Select ", _interpolate(_named("select")), "."])},
      "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Choose ", _interpolate(_named("open_src")), ". After the project is loaded we need to setup the Toolchains."])},
      "5": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("toolchains")), "."])},
      "6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click ", _interpolate(_named("plus")), " and select ", _interpolate(_named("minGW")), "."])},
      "7": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["In the ", _interpolate(_named("environment")), " field put path to the ", _interpolate(_named("minGW")), " installation."])},
      "8": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click ", _interpolate(_named("ok")), "."])},
      "9": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Go to ", _interpolate(_named("cMake")), " tab and wait for ", _interpolate(_named("cMake")), " file loading complete."])},
      "p2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If the process went well you should see ", _interpolate(_named("finished")), " message and in the Run Configuration list run configurations will appear. To run configuration you should click ", _interpolate(_named("build")), ", but not ", _interpolate(_named("run")), " icon. ", _interpolate(_named("ul_configs"))])},
      "ul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two main run configurations are:"])},
      "li1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["build the firmware"])},
      "li2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["build the firmware and flash the device"])}
    },
    "arduino": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting up Arduino core for the ESP32"])},
      "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Open ", _interpolate(_named("bold_gitBash")), " and clone the repository into the ", _interpolate(_named("components")), " folder: ", _interpolate(_named("arduino_code")), "Stable version is available only for ", _interpolate(_named("esp3")), ", because of this we need to switch to the ", _interpolate(_named("v4_0")), " branch, that is in development. ", _interpolate(_named("arduino_code_2"))])},
      "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Switch to ", _interpolate(_named("clion")), " and run", _interpolate(_named("idf_py")), ", in the Terminal tab, if you got an error then stretch out the terminal window and maximize the ", _interpolate(_named("clion")), " window."])},
      "3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mark the checkbox ", _interpolate(_named("comp_config")), "."])},
      "4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mark the checkbox ", _interpolate(_named("arduino_config")), "."])},
      "5": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click ", _interpolate(_named("save")), ", and then ", _interpolate(_named("exit")), "."])},
      "6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["On this step we will do a workaround, that will allow us to use 4th version of the esp-ids framework. When 4th version of the ", _interpolate(_named("arduino_core")), " framework became stable, it is possible that we don't need this workaround."])},
      "sublist": {
        "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Open ", _interpolate(_named("arduino_open")), "."])},
        "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Add line ", _interpolate(_named("arduino_add")), " right after the ", _interpolate(_named("arduino_add_2")), "."])}
      },
      "7": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rename ", _interpolate(_named("arduino_rename")), "."])},
      "8": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Replace content of the main.cpp to : ", _interpolate(_named("arduino_code_3"))])},
      "9": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Switch to ", _interpolate(_named("cMake")), " tab and click ", _interpolate(_named("reload_cMake")), " icon."])},
      "10": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click ", _interpolate(_named("build")), " icon."])},
      "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So now we got standard Arduino IDE sketch and all features that came with CLion IDE."])}
    },
    "software-versions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software versions used to write this tutorial:"])}
    },
    "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links:"])}
  },
  "dev_in_a_cont": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development in a Container"])},
    "description": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This article is providing instructions how to create a Docker image with configured environment for developing ESP32 projects and how to connect to this environment with CLion IDE."])},
      "p_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It takes some time and effort to configure ESP-IDF and CLion to develop projects for ESP32. And if you need to switch to another PC or operating system you will need to go through all the configuration steps again."])},
      "p_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To solve this it is possible to utilize the Remote Development feature from the CLion IDE and create a Docker image that could have all required software preconfigured. Even if you are not using CLion you can use such image to build and debug your projects with a command line tools."])},
      "p_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For remote development we will use open-ssh server, for building the UI nodejs and esp-idf toolchain for building and flashing ESP32 images."])},
      "p_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["How to configure ssh in docker is described in ", _interpolate(_named("this_article")), " article. Official Espressif ", _interpolate(_named("dockerfile_link")), " could help us to get building environment in Docker."])},
      "p_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So, to start using the docker image you need to build it, run it and connect to it with CLion."])},
      "this": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this"])}
    },
    "build-docker": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build and run Docker image"])},
      "list-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prerequisites:"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you have .ssh folder with your keys in the home folder"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your device is attached to the usb port of the host machine If these conditions are not met just update docker-compose file by your needs before running the image."])},
      "list": {
        "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Clone the repository clone ", _interpolate(_named("clone_docker"))])},
        "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Build docker image ", _interpolate(_named("build_docker"))])},
        "3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Start container with docker-compose ", _interpolate(_named("start_docker"))])}
      },
      "p_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Now you should be able to connect with ssh to your container ", _interpolate(_named("docker_code_1")), " where 192.168.3.254 is your host ip. Use password ", _interpolate(_named("docker_password")), "."])},
      "p_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Update permissions to mounted volumes (this step should be done only once) ", _interpolate(_named("docker_code_2"))])},
      "p_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Configure your git username and email (this step could be skipped if you are not going to push code to your repository) ", _interpolate(_named("docker_code_3"))])},
      "p_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Now you can either clone your project into ~/src folder or copy one of the examples from the esp-idf ", _interpolate(_named("docker_code_4"))])}
    },
    "connect-to-docker": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to the Docker image with CLion"])},
      "1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Run ", _interpolate(_named("clion")), " and choose ", _interpolate(_named("highlighted_1"))])},
      "2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click ", _interpolate(_named("highlighted_2")), " and fill in your connection parameters"])},
      "3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click the ", _interpolate(_named("highlighted_3")), " button"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Then choose IDE version and Project folder. IDE version should be the same version that you are running locally."])},
      "5": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Click ", _interpolate(_named("highlighted_4"))])},
      "6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["In the ", _interpolate(_named("highlighted_8")), " ignore the warning and click ", _interpolate(_named("highlighted_5")), " and then ", _interpolate(_named("highlighted_6"))])},
      "7": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If everything went well you should see a ", _interpolate(_named("highlighted_7")), " record in the ", _interpolate(_named("cMake")), " tab"])},
      "8": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Use ", _interpolate(_named("highlighted_9")), " dropdown to choose the ", _interpolate(_named("cMake")), " target and a ", _interpolate(_named("build")), " icon to execute them."])},
      "p": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If you don't want to use git to clone the repo you can just copy files from here: ", _interpolate(_named("dockerfile"))])}
    }
  }
}