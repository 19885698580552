<template>
  <div class="container right">
    <div class="content">
      <h2>{{ name }}</h2>
      <div class="border-hr"></div>
      <h3>{{ title }}</h3>
      <p>{{ description }}</p>
      <router-link :to="link">
        <span @mouseover="hovered = true" @mouseleave="hovered = false">
          <img v-if="!hovered" class="arrow" src="@/assets/images/arrow.png" alt="arrow"/>
          <img v-else-if="hovered" class="arrow" src="@/assets/images/arrow2.png" alt="arrow">
        {{ more }}</span>
      </router-link>
    </div>
    <img class="image" :src="icon" alt="icon of device"/>
  </div>
</template>

<script>
export default {
  name: "DeviceHome",
  props: ["name", "title", "description", "more", "icon", "link", "arrow"],
  data() {
    return {
      hovered: false
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  @extend %flex-row;
  align-items: center;

  .content {
    flex: 65%;

    h2 {
      @extend %text-uppercase;
      @include for-size(phone) {
        margin-top: calc($size-lrg / 2);
      }
    }
  }

  .image {
    @extend %radius-circle;
    height: $img-dev;
    width: $img-dev;
    margin: $size-lrg;
    @include for-size(phone) {
      display: none;
    }
  }

  @include for-size(tablet-portrait) {
    .content {
      flex: 2;
    }

    .image {
      height: calc($img-dev * 1.25);
      width: calc($img-dev * 1.25);
    }
  }
}
</style>
