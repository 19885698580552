<template id="app">
  <div class="wrapper">
    <header>
      <LocaleSwitcher/>
      <MenuNav/>
    </header>
    <router-view/>
    <footer class="main-container">
      <div class="border-hr"></div>
      <div class="footer">
        <span>Contact us</span><img src=@/assets/images/email-image.png alt="">
      </div>
    </footer>
  </div>
</template>
<script>
import LocaleSwitcher from "@/components/LocaleSwitcher";
import MenuNav from "@/components/MenuNav";

export default {
  name: "App",
  components: {MenuNav, LocaleSwitcher}
};
</script>
<style lang="scss">
#app {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: {
    attachment: fixed;
    image: url("#{$main-bg}");
    size: cover;
  }
  font-family: 'Raleway', Helvetica sans-serif;

  .wrapper {
    background: $dark-opacity;
    color: $base-white;

    header {
      @extend %flex-row;
      justify-content: space-between;
      background-color: $dark-opacity-2;
      padding: calc($size-lrg / 4);
      position: fixed;
      width: 100%;
      @include for-size(desktop) {
        padding: calc($size-lrg / 2);
      }
    }

    .footer {
      font-size: calc($size-lrg / 2);
      padding: {
        bottom: calc($size-lrg / 2);
        top: 0;
      }
      @extend %flex-row;
      align-items: center;
      @include for-size(desktop) {
        font-size: $size-sml;
        padding: {
          bottom: $size-lrg;
          top: $size-lrg;
        }
      }
    }
  }
}
</style>
