<template>
  <div class="select">
    <label>
      <select v-model="$i18n.locale">
        <option
            v-for="(locale, i) in locales"
            :key="`locale-${i}`"
            :value="locale"
        >
          {{ locale }}
        </option>
      </select>
    </label>
  </div>
</template>

<script>
export default {
  name: "LocaleSwitcher",
  data() {
    return {locales: ["ua", "en"]};
  },
};
</script>
<style lang="scss" scoped>
select {
  @extend %border-base;
  @extend %border-radius;
  appearance: none;
  background: none;
  color: $base-white;
  font-size: $size-med;
  padding: calc($indent / 3);

  &:hover {
    @extend %radius-circle;
  }

  option {
    background-color: $base-color;
  }

  @include for-size(desktop) {
    font-size: $size-lrg;
    padding: calc($indent / 1.6);
  }
}
</style>
